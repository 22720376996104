import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Thanks for the Ride</h1>
    <p>Ten years ago we started rideMinnesota to help grow the Minnesota riding community. It was never meant to make money, but to be a platform for local riders to gain credibility. I’m proud to say we succeeded in helping jump-start several careers and also keeping people informed about local riding news.</p>
    <p>Over the last couple years, personal priorities have taken precedence over rideMinnesota and for that I apologize. I kept telling myself that I would have time to update the site, but the reality is I don’t at this time. It is a hard decision, but I think now is the time to put the site to rest.</p>
    <p>I would like to personally thank anybody that supported the site throughout its 10 years of existence. It has been an incredible ride and I hope it made an impact in the community.</p>
    <p>See you on the slopes.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
  </Layout>
)

export default IndexPage
